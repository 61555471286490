






















import {Component, Vue} from 'vue-property-decorator';

import SelectorSite from '@/site/components/SelectorSite.vue';

@Component({
  components: {
    SelectorSite,
  },
})
export default class Portal extends Vue {}
