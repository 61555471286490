var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.smAndDown)?_c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.fromAppBar)?_c('v-card',_vm._g(_vm._b({staticClass:"\n        Card\n        d-flex\n        justify-space-between justify-md-space-around\n        align-center\n        mx-4\n        mb-4\n        mr-md-4\n        rounded\n      ",attrs:{"align":"left","min-height":"96","width":"100%","elevation":"0"},on:{"click":function($event){_vm.loginDialog = !_vm.accessToken}}},'v-card',attrs,false),on),[_c('div',{staticClass:"ma-3 d-flex"},[_c('div',{staticClass:"tertiary pa-3 rounded"},[_c('v-icon',{staticClass:"flex-grow-0",attrs:{"medium":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.accountService.icon)+" ")])],1)]),_c('div',{staticClass:"d-flex flex-column justify-start flex-grow-1"},[_c('h2',{staticClass:"text-h2 pl-4 font-weight-medium"},[_vm._v(_vm._s(_vm.accountService.title))]),_c('span',{staticClass:"caption font-weight-regular px-4"},[_vm._v(" "+_vm._s(_vm.accountService.description)+" ")])])]):_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","icon":"","color":_vm.accessToken ? 'primary' : 'gray'},on:{"click":function($event){_vm.loginDialog = !_vm.accessToken}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.svgIcons.accountCircle))])],1)]}}],null,false,3253156092),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[(_vm.accessToken)?_c('v-list',{staticClass:"rounded-lg rounded-b-0 pb-4"},[_c('v-list-item',[_c('v-list-item-title',{staticClass:"text-h2 font-weight-medium"},[_c('v-text-field',{attrs:{"filled":"","disabled":"","value":_vm.email,"flat":"","hide-details":"auto"}})],1)],1),_c('v-list-item',{on:{"click":function($event){(_vm.sheet = false),
          _vm.selectService({
            name: _vm.routes.badge,
            params: {slug: _vm.$route.params.slug},
          })}}},[_c('v-list-item-title',{staticClass:"text-h2 font-weight-medium d-flex justify-space-between align-center"},[_c('span',[_vm._v("Recharger ma carte "),_c('span',[_vm._v(" (Solde: "+_vm._s((_vm.balance / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', }))+")")])]),_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.chevronRight)+" ")])],1)],1),_c('v-list-item',{on:{"click":function($event){(_vm.sheet = false), _vm.logout(_vm.accessToken)}}},[_c('v-list-item-title',{staticClass:"text-h2 font-weight-medium d-flex justify-space-between align-center"},[_c('span',[_vm._v("Déconnexion")]),_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.logout)+" ")])],1)],1)],1):(_vm.loginDialog)?_c('v-dialog',{attrs:{"max-width":"700px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":function($event){(_vm.loginDialog = false), _vm.goHome()}},model:{value:(_vm.loginDialog),callback:function ($$v) {_vm.loginDialog=$$v},expression:"loginDialog"}},[_c('LoginModal',{on:{"closeModal":function($event){(_vm.loginDialog = false), (_vm.sheet = _vm.accessToken.length > 0), _vm.goHome()}}})],1):_vm._e()],1):_c('div',[_c('v-menu',{staticClass:"flex-grow-0",attrs:{"bottom":"","left":"","color":"white","close-on-content-click":false,"transition":"scale-transition","offset-y":"","z-index":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","icon":"","color":_vm.accessToken ? 'primary' : 'gray'},on:{"click":function($event){_vm.loginDialog = !_vm.accessToken}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.svgIcons.accountCircle))])],1)]}}])},[(_vm.accessToken)?_c('v-list',{staticClass:"rounded-lg rounded-b-0 pb-4"},[_c('v-list-item',[_c('v-list-item-title',{staticClass:"text-h2 font-weight-medium"},[_c('v-text-field',{attrs:{"filled":"","disabled":"","value":_vm.email,"flat":"","hide-details":"auto"}})],1)],1),_c('v-list-item',{on:{"click":function($event){(_vm.sheet = false),
            _vm.selectService({
              name: _vm.routes.badge,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c('v-list-item-title',{staticClass:"text-h2 font-weight-medium d-flex justify-space-between align-center"},[_c('span',[_vm._v("Recharger ma carte "),_c('span',[_vm._v(" (Solde: "+_vm._s((_vm.balance / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', }))+")")])]),_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.chevronRight)+" ")])],1)],1),_c('v-list-item',{on:{"click":function($event){(_vm.sheet = false), _vm.logout(_vm.accessToken)}}},[_c('v-list-item-title',{staticClass:"text-h2 font-weight-medium d-flex justify-space-between align-center"},[_c('span',[_vm._v("Déconnexion")]),_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.logout)+" ")])],1)],1)],1):(_vm.loginDialog)?_c('v-dialog',{attrs:{"max-width":"700px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":function($event){(_vm.loginDialog = false), _vm.goHome()}},model:{value:(_vm.loginDialog),callback:function ($$v) {_vm.loginDialog=$$v},expression:"loginDialog"}},[_c('LoginModal',{on:{"closeModal":function($event){(_vm.loginDialog = false), _vm.goHome()}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }