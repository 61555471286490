/* eslint-disable @typescript-eslint/ban-types */
import IoService from '../services/IoService';

const initPwd = '12345';

export const actions = {
  login: async (
    {commit}: {commit: Function},
    form: {email: string; password: string; brandId: string}
  ): Promise<void> => {
    commit('user/SET_LOADING', true, {root: true});
    await IoService.login(form.email, form.password, form.brandId)
      .then((response) => {
        const {data} = response;
        commit('user/SET_ACCESS_TOKEN', data.access_token, {root: true});
        commit('user/SET_USER_ID', data.data.customer.customerId, {root: true});
        commit('user/SET_EMAIL', data.data.customer.email, {root: true});
        commit('user/SET_ERROR', false, {root: true});
        commit('user/SET_ERROR_MESSAGE', '', {root: true});
        commit('user/SET_NEW_USER', form.password === initPwd, {root: true});

        window.localStorage.setItem('accessToken', data.access_token);
        window.localStorage.setItem('userId', data.data.customer.customerId);

        if (form.password !== initPwd)
          actions.balance(
            {commit},
            {token: data.access_token, userId: data.data.customer.customerId}
          );
      })
      .catch((e) => {
        commit('user/SET_ERROR', true, {root: true});
        commit('user/SET_ERROR_MESSAGE', e.response.data.message, {root: true});
      })
      .finally(() => commit('user/SET_LOADING', false, {root: true}));
  },
  me: async ({commit}: {commit: Function}, token: string): Promise<void> => {
    commit('user/SET_LOADING', true, {root: true});
    await IoService.me(token)
      .then((response) => {
        const {data} = response;
        commit('user/SET_USER_ID', data.data.customerId, {root: true});
        commit('user/SET_EMAIL', data.data.email, {root: true});

        window.localStorage.setItem('userId', data.data.customerId);

        actions.balance({commit}, {token, userId: data.data.customerId});
      })
      .catch(() => {
        commit('user/SET_ERROR', true, {root: true});
        commit('user/SET_ACCESS_TOKEN', '', {root: true});
        commit('user/SET_USER_ID', '', {root: true});
        window.localStorage.setItem('accessToken', '');
        window.localStorage.setItem('userId', '');
      })
      .finally(() => commit('user/SET_LOADING', false, {root: true}));
  },
  logout: async ({commit}: {commit: Function}, token: string): Promise<void> => {
    commit('user/SET_LOADING', true, {root: true});
    await IoService.logout(token)
      .then(() => {
        commit('user/SET_ACCESS_TOKEN', '', {root: true});
        commit('user/SET_USER_ID', '', {root: true});
        commit('user/SET_BALANCE', 0, {root: true});
        window.localStorage.setItem('accessToken', '');
        window.localStorage.setItem('userId', '');
      })
      .catch(() => {
        commit('user/SET_ERROR', true, {root: true});
      })
      .finally(() => commit('user/SET_LOADING', false, {root: true}));
  },
  forgottenPassword: async ({commit}: {commit: Function}, email: string): Promise<void> => {
    commit('user/SET_LOADING', true, {root: true});
    await IoService.forgottenPassword(email)
      .catch(() => {
        commit('user/SET_ERROR', true, {root: true});
      })
      .finally(() => commit('user/SET_LOADING', false, {root: true}));
  },
  balance: async (
    {commit}: {commit: Function},
    form: {userId: string; token: string}
  ): Promise<void> => {
    await IoService.balance(form.userId, form.token).then((response) => {
      commit('user/SET_BALANCE', response.data.data.customerBalance, {root: true});
    });
  },
  accessToken: ({commit}: {commit: Function}, token: string): void => {
    commit('user/SET_ACCESS_TOKEN', token, {root: true});
  },
};
