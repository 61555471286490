import {AxiosResponse} from 'axios';

import axios from '@/core/tools/axios';

import {FeedbackContact, FeedBackProduct} from '../interfaces';

export default class FeedbackService {
  static sendFeedback(formContact: FeedbackContact): Promise<AxiosResponse> {
    return axios.post('/feedback/', formContact);
  }

  static sendFeedbackProduct(feedbackProduct: FeedBackProduct): Promise<AxiosResponse> {
    return axios.post('/feedback/product', feedbackProduct);
  }
}
