import {FeedBackProduct, FeedBackProductCompleted, FeedbackState} from '../interfaces';

export const getters = {
  error: (state: FeedbackState): boolean => state.error,
  loading: (state: FeedbackState): boolean => state.loading,
  response: (state: FeedbackState): boolean => state.response,
  errorMessage: (state: FeedbackState): string => state.errorMessage,
  feedBackProduct: (state: FeedbackState): FeedBackProduct | null => state.feedBackProduct,
  feedBackProductsCompleted: (state: FeedbackState): FeedBackProductCompleted[] =>
    state.feedBackProductsCompleted,
};
