




import {Component, Vue} from 'vue-property-decorator';

import NoData from '@/core/components/NoData.vue';

@Component({
  components: {
    NoData,
  },
})
export default class PageNotFound extends Vue {}
