import {
  mdiAccount,
  mdiAccountCircle,
  mdiAccountGroupOutline,
  mdiAlert,
  mdiApps,
  mdiCalendarRangeOutline,
  mdiCalendarToday,
  mdiChartBar,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClockTimeFourOutline,
  mdiClose,
  mdiHelpCircleOutline,
  mdiHome,
  mdiLogout,
  mdiMagnify,
  mdiMapMarker,
  mdiMinus,
  mdiNewspaper,
  mdiPencil,
  mdiPlus,
  mdiRoomService,
  mdiShopping,
  mdiSilverware,
  mdiStar,
  mdiTableChair,
  mdiTicketConfirmation,
} from '@mdi/js';

export const mdiSvg = {
  alert: mdiAlert,
  account: mdiAccount,
  accountCircle: mdiAccountCircle,
  apps: mdiApps,
  chartBar: mdiChartBar,
  calendarToday: mdiCalendarToday,
  chevronRight: mdiChevronRight,
  chevronDown: mdiChevronDown,
  chevronUp: mdiChevronUp,
  close: mdiClose,
  helpCircleOutline: mdiHelpCircleOutline,
  home: mdiHome,
  logout: mdiLogout,
  mapMarker: mdiMapMarker,
  magnify: mdiMagnify,
  newspaper: mdiNewspaper,
  pencil: mdiPencil,
  roomService: mdiRoomService,
  silverware: mdiSilverware,
  shopping: mdiShopping,
  star: mdiStar,
  ticketConfirmation: mdiTicketConfirmation,
  tableChair: mdiTableChair,
  calendarRangeOutline: mdiCalendarRangeOutline,
  clockTimeFourOutline: mdiClockTimeFourOutline,
  accountGroupOutline: mdiAccountGroupOutline,
  plus: mdiPlus,
  minus: mdiMinus,
};
