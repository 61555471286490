import {actions} from './actions';
import {getters} from './getters';
import {mutations} from './mutations';
import {state} from './state';

// Export user store
export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
