







import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import NoData from '@/core/components/NoData.vue';

import Posts from './Posts.vue';

@Component({
  components: {
    Posts,
    NoData,
  },
})
export default class News extends Vue {
  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;
}
