import {UserState} from '../interfaces/UserState';

export const mutations = {
  SET_ACCESS_TOKEN(state: UserState, accessToken: string): void {
    state.accessToken = accessToken;
  },
  SET_USER_ID(state: UserState, userId: string): void {
    state.userId = userId;
  },
  SET_EMAIL(state: UserState, email: string): void {
    state.email = email;
  },
  SET_BALANCE(state: UserState, balance: number): void {
    state.balance = balance;
  },
  SET_ERROR(state: UserState, error: boolean): void {
    state.error = error;
  },
  SET_ERROR_MESSAGE(state: UserState, errorMessage: string): void {
    state.errorMessage = errorMessage;
  },
  SET_LOADING(state: UserState, loading: boolean): void {
    state.loading = loading;
  },
  SET_NEW_USER(state: UserState, isNewUser: boolean): void {
    state.isNewUser = isNewUser;
  },
};
