











































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';

import BaseService from './BaseService.vue';
import LoginModal from './LoginModal.vue';

@Component({components: {LoginModal}})
export default class Account extends BaseService {
  @Prop() fromAppBar!: boolean;

  sheet = false;

  @Watch('$store.state.user.accessToken')
  updateDialog(): void {
    this.loginDialog = !this.accessToken;
  }

  mounted(): void {
    this.loginDialog = !this.accessToken;
  }
}
