var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$route.name !== _vm.routes.notFound && !!_vm.siteName)?_c('AppBar',{staticClass:"AppBar"}):_vm._e(),(_vm.updateExists || _vm.isOffline)?_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'container' : 'Alert'},[(_vm.updateExists && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))?_c('v-alert',{staticClass:"rounded-1 my-2",attrs:{"dense":"","type":"info","dismissible":"","max-width":"100%"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('span',{staticClass:"caption"},[_vm._v("Une nouvelle version est disponible.")])]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.refreshApp}},[_c('span',{staticClass:"caption"},[_vm._v("Rafraîchir")])])],1)],1)],1):_vm._e(),(_vm.isOffline && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))?_c('v-alert',{staticClass:"rounded-1 my-2",attrs:{"dense":"","icon":_vm.svgIcons.alert,"type":"warning","dismissible":"","max-width":"100%"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('span',{staticClass:"caption"},[_vm._v("Vous êtes hors connection.")])])],1)],1):_vm._e()],1):_vm._e(),(
      (_vm.$vuetify.breakpoint.smAndDown && !!_vm.siteName && _vm.$route.name !== _vm.routes.menu) ||
      (!_vm.$vuetify.breakpoint.smAndDown &&
        !!_vm.siteName &&
        _vm.$route.name !== _vm.routes.menu &&
        _vm.$route.name !== _vm.routes.home) ||
      (_vm.days.length > 0 && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))
    )?_c('Banner'):_vm._e(),_c('v-main',{staticClass:"background",class:_vm.$vuetify.breakpoint.smAndDown && _vm.$route.name !== _vm.routes.notFound
        ? (!!_vm.siteName &&
            _vm.$route.name !== _vm.routes.menu &&
            _vm.$route.name !== _vm.routes.badge &&
            _vm.$route.name !== _vm.routes.clickAndCollect &&
            _vm.$route.name !== _vm.routes.roomService) ||
          (_vm.days.length > 0 && _vm.$route.name === _vm.routes.menu)
          ? 'mainMobile'
          : 'mainMobile linkMobile'
        : (!!_vm.siteName &&
            _vm.$route.name !== _vm.routes.menu &&
            _vm.$route.name !== _vm.routes.home &&
            _vm.$route.name !== _vm.routes.menu &&
            _vm.$route.name !== _vm.routes.badge &&
            _vm.$route.name !== _vm.routes.clickAndCollect &&
            _vm.$route.name !== _vm.routes.roomService) ||
          (_vm.days.length > 0 && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))
        ? 'mainDesktop'
        : ''},[(_vm.$vuetify.breakpoint.smAndDown)?_c('transition',{attrs:{"name":"app","mode":"out-in"}},[_c('router-view')],1):_c('router-view')],1),(
      _vm.$vuetify.breakpoint.smAndDown &&
      _vm.$route.name !== _vm.routes.notFound &&
      _vm.$route.name !== _vm.routes.home
    )?_c('BottomBar'):_vm._e(),_c('v-overlay',{attrs:{"absolute":false,"value":_vm.isOpenSelector && _vm.$route.name !== _vm.routes.home,"color":"secondary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }