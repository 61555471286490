import Axios, {AxiosResponse} from 'axios';

export default class IoService {
  static login(username: string, password: string, brandId: string): Promise<AxiosResponse> {
    return Axios.post('https://api.innovorder.fr/oauth/login', {
      username,
      password,
      grant_type: 'password',
      rememberMe: true,
      brandId,
    });
  }

  static me(token: string): Promise<AxiosResponse> {
    return Axios.get('https://api.innovorder.fr/oauth/me', {
      headers: {authorization: `Bearer ${token}`},
    });
  }

  static logout(token: string): Promise<AxiosResponse> {
    return Axios.get('https://api.innovorder.fr/oauth/logout', {
      headers: {authorization: `Bearer ${token}`},
    });
  }

  static forgottenPassword(email: string): Promise<AxiosResponse> {
    return Axios.post('https://api.innovorder.fr/customers/forgotten_password', {
      email,
    });
  }

  static balance(userId: string, token: string): Promise<AxiosResponse> {
    return Axios.get(`https://api.innovorder.fr/customers/${userId}/balance?unscoped=true`, {
      headers: {authorization: `Bearer ${token}`},
    });
  }
}
