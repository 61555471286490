var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-navigation',{attrs:{"app":"","color":"primary","height":"75"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-btn',{staticClass:"mr-2 text-navigationBtn pr-3 pl-4 py-4",attrs:{"text":"","rounded":"","ripple":false,"value":"home"},on:{"click":function($event){return _vm.selectService({
        name: 'home',
        params: {slug: _vm.$route.params.slug},
      })}}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.home)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v("Accueil")])],1)]),_vm._l((_vm.services),function(service){return _c('v-btn',{key:service.name,staticClass:"mr-2 text-navigationBtn px-3 py-4",attrs:{"text":"","exact":"","rounded":"","ripple":false,"value":service.name},on:{"click":function($event){service.name
        ? _vm.selectService({
            name: service.name,
            params: {slug: _vm.$route.params.slug},
          })
        : ''}}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(service.icon)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v(_vm._s(service.title || service.name))])],1)])}),(_vm.enableAffluence && _vm.customerId)?_c('v-dialog',{attrs:{"persistent":_vm.$vuetify.breakpoint.smAndDown,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 text-navigationBtn px-3 py-4",attrs:{"text":"","exact":"","rounded":"","ripple":false}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.chartBar)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v("Affluence")])],1)])]}}],null,false,3803207802),model:{value:(_vm.affluenceDialog),callback:function ($$v) {_vm.affluenceDialog=$$v},expression:"affluenceDialog"}},[_c('AffluenceModal',{on:{"closeModal":function($event){_vm.affluenceDialog = false}}})],1):_vm._e(),_c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-navigationBtn pl-3 pr-4 py-4"},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.roomServiceIcon)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v("Room service")])],1)])]}}]),model:{value:(_vm.bottomNavRoomService),callback:function ($$v) {_vm.bottomNavRoomService=$$v},expression:"bottomNavRoomService"}},[_c('v-list',{staticClass:"rounded-lg rounded-b-0 pb-8"},[_c('v-list-item',{attrs:{"href":'/others/' + _vm.pathRoomServiceFile,"download":'Room Service - ' + _vm.name + '.pdf'},on:{"click":function($event){_vm.bottomNavRoomService = false}}},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"32px","tile":""}},[_c('v-icon',{attrs:{"color":"secondary","medium":""}},[_vm._v(" "+_vm._s(_vm.roomServiceIcon)+" ")])],1)],1),_c('v-list-item-title',{staticClass:"secondary--text text-h2 font-weight-medium"},[_vm._v("Télécharger la carte Room service")])],1),_c('v-list-item',{attrs:{"href":'/others/' + _vm.pathOrderFile,"download":'Bon de commande - ' + _vm.name + '.xlsx'},on:{"click":function($event){_vm.bottomNavRoomService = false}}},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"32px","tile":""}},[_c('v-icon',{attrs:{"color":"secondary","medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.ticketConfirmation)+" ")])],1)],1),_c('v-list-item-title',{staticClass:"secondary--text text-h2 font-weight-medium"},[_vm._v("Télécharger le bon de commande")])],1)],1)],1),(_vm.onlineServices.length)?_c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-navigationBtn pl-3 pr-4 py-4"},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.apps)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v("Services")])],1)])]}}],null,false,3634108487),model:{value:(_vm.bottomNavServices),callback:function ($$v) {_vm.bottomNavServices=$$v},expression:"bottomNavServices"}},[_c('v-list',{staticClass:"rounded-lg rounded-b-0 pb-8"},[_vm._l((_vm.onlineServices),function(service){return _c('v-list-item',{key:service.name,on:{"click":function($event){(_vm.bottomNavServices = false),
            _vm.selectService({
              name: service.name,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"32px","tile":""}},[_c('v-icon',{attrs:{"color":"secondary","medium":""}},[_vm._v(" "+_vm._s(service.icon)+" ")])],1)],1),_c('v-list-item-title',{staticClass:"secondary--text text-h2 font-weight-medium"},[_vm._v(_vm._s(service.title || service.name))])],1)}),(_vm.enableContact)?_c('v-list-item',{on:{"click":function($event){(_vm.bottomNavServices = false),
            _vm.selectService({
              name: _vm.routes.contact,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"32px","tile":""}},[_c('v-icon',{attrs:{"color":"secondary","medium":""}},[_vm._v(" "+_vm._s(_vm.contactIcon)+" ")])],1)],1),_c('v-list-item-title',{staticClass:"secondary--text text-h2 font-weight-medium"},[_vm._v("Contact")])],1):_vm._e()],2)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }