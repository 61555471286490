
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {Site} from '@/site/interfaces';

import {nameRoutes} from '../tools';

@Component
export default class NoData extends Vue {
  @Getter('siteList', {namespace: 'site'}) siteList!: Site[];

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Prop(Boolean) isNotFoundPage!: boolean;

  @Prop(Boolean) hasItems!: boolean;

  @Prop(String) typeData!: string;

  routes = nameRoutes;
}
