
import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {mdiSvg} from '@/core';

import {Picto, Product, Zone} from '../interfaces';
import {
  filterAllergenes,
  filterNutriscore,
  filterOrigines,
  filterType,
  filterZone,
  formatPrice,
} from '../tools';

@Component
export default class BaseProduct extends Vue {
  @Getter('zones', {namespace: 'menu'}) zones!: Zone[];

  svgIcons = mdiSvg;

  updateRoute(open: boolean, product: Product): void {
    if (open) {
      if (this.$route.hash) {
        this.$router.replace(`#${product.name}`);
      } else {
        this.$router.push(`#${product.name}`);
      }
    } else {
      this.$router.back();
    }
  }

  getPrice(product: Product): string {
    if (product.price) {
      return formatPrice(product.price.toString(10));
    }
    return '';
  }

  getAllergenes(product: Product): Picto[] {
    if (product.allergens.length) {
      return filterAllergenes(product.allergens);
    }
    return [];
  }

  getOrigines(product: Product): Picto[] {
    if (product.origins.length) {
      return filterOrigines(product.origins);
    }
    return [];
  }

  getZoneName(product: Product): string | null {
    return filterZone(product, this.zones)?.name || null;
  }

  getImageZone(product: Product): string | null {
    return filterZone(product)?.fileName || null;
  }

  getType = function getType(product: Product): string {
    return filterType(product);
  };

  getNutriscore(isVertical: boolean, product: Product): string {
    if (product.nutriscore) return filterNutriscore(product.nutriscore, isVertical);
    return '';
  }
}
