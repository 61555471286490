























import {Component} from 'vue-property-decorator';

import BaseService from '../components/BaseService.vue';
import HelperLogin from '../components/HelperLogin.vue';
import Iframe from '../components/Iframe.vue';
import LoginModal from '../components/LoginModal.vue';

@Component({components: {Iframe, LoginModal, HelperLogin}})
export default class CreateAccount extends BaseService {}
