import spacetime from 'spacetime';

import {isBeforeDate, tzParis} from '@/core/tools';

import {Day, Menu, Service} from '../interfaces';
import {hasMenus} from './product.tools';

export function isBeforeService(m: string, n: string, x?: string): boolean {
  const startAt = new Date().setHours(parseInt(m.split(':')[0], 10), parseInt(m.split(':')[1], 10));
  const endAt = new Date().setHours(parseInt(n.split(':')[0], 10), parseInt(n.split(':')[1], 10));
  if (x) {
    const nextStartAt = new Date().setHours(
      parseInt(x.split(':')[0], 10),
      parseInt(x.split(':')[1], 10)
    );

    return (
      (spacetime.now(tzParis).isBefore(spacetime(startAt, tzParis)) ||
        spacetime.now(tzParis).isBefore(spacetime(endAt, tzParis))) &&
      spacetime(startAt, tzParis).isBefore(spacetime(nextStartAt, tzParis))
    );
  }
  return (
    spacetime.now(tzParis).isBefore(spacetime(startAt, tzParis)) ||
    spacetime.now(tzParis).isBefore(spacetime(endAt, tzParis))
  );
}

export function isNextDayService(m: string, x: string): boolean {
  const startAt = new Date().setHours(parseInt(m.split(':')[0], 10), parseInt(m.split(':')[1], 10));
  const nextStartAt = new Date().setHours(
    parseInt(x.split(':')[0], 10),
    parseInt(x.split(':')[1], 10)
  );
  return isBeforeDate(startAt, nextStartAt);
}

export function isNextServiceAvailable(services: Service[], menus: Menu[], day: Day): boolean {
  return services.some(
    (service) => isBeforeService(service.startAt, service.endAt) && hasMenus(menus, day, service)
  );
}
