import {UserState} from '../interfaces/UserState';

export const state: UserState = {
  accessToken: '',
  userId: '',
  error: false,
  errorMessage: '',
  loading: false,
  response: false,
  balance: 0,
  email: '',
  isNewUser: false,
};
