


























import {Component, Watch} from 'vue-property-decorator';

import BaseService from '../components/BaseService.vue';
import HelperLogin from '../components/HelperLogin.vue';
import Iframe from '../components/Iframe.vue';
import LoginModal from '../components/LoginModal.vue';

@Component({components: {Iframe, LoginModal, HelperLogin}})
export default class ClickAndCollect extends BaseService {
  @Watch('$store.state.user.accessToken')
  updateDialog(): void {
    this.loginDialog = !this.accessToken;
    this.redirectNewUser();
  }

  mounted(): void {
    this.loginDialog = !this.accessToken;
    this.redirectNewUser();
  }
}
