




import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Iframe extends Vue {
  @Prop() readonly link!: string;

  @Prop() readonly title!: string;
}
