import {FeedbackState} from '../interfaces';

export const state: FeedbackState = {
  error: false,
  loading: false,
  response: false,
  errorMessage: '',
  feedBackProduct: null,
  feedBackProductsCompleted: [],
};
