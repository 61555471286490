

















import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {Article} from '../interfaces';
import ModalPostDetail from './ModalPostDetail.vue';

@Component({components: {ModalPostDetail}})
export default class Posts extends Vue {
  @Getter('articles', {namespace: 'article'}) articles!: Article[];
}
