




























































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {mdiSvg} from '@/core';

import {PRODUCT_TYPE, PRODUCT_TYPES} from '../constants';
import {Picto, Product, Zone} from '../interfaces';
import {filterAllergenes, filterOrigines, formatPrice, productsByTypeProduct} from '../tools';

@Component
export default class ModalFormule extends Vue {
  @Prop() readonly formula!: Zone;

  @Getter('formulaPrice', {namespace: 'menu'}) formulaPrice!: (id: string) => string;

  @Getter('formulaProducts', {namespace: 'menu'}) formulaProducts!: (id: string) => Product[];

  dialog = false;

  productTypes: Picto[] = [];

  products!: Product[];

  svgIcons = mdiSvg;

  @Watch('$store.state.menu.selectedDay')
  updateDay(): void {
    this.initFormula();
  }

  @Watch('$store.state.menu.selectedService')
  updateService(): void {
    this.initFormula();
  }

  @Watch('$route.hash')
  updateDialog(newHash: string, oldHash: string): void {
    if (newHash === `#${this.formula.name}`) {
      this.dialog = true;
    } else if (oldHash === `#${this.formula.name}`) {
      this.dialog = false;
    }
  }

  updateRoute(open: boolean): void {
    if (open) {
      this.$router.push(`#${this.formula.name}`);
    } else {
      this.$router.back();
    }
  }

  productsByTypeProduct = function productsByType(
    products: Product[],
    typeProduct: PRODUCT_TYPE | string
  ): Product[] {
    return productsByTypeProduct(products, typeProduct);
  };

  getPrice = function getPrice(price: number): string {
    return formatPrice(price.toString(10));
  };

  getAllergenes = function getAllergenes(product: Product): Picto[] {
    if (product.allergens.length) {
      return filterAllergenes(product.allergens);
    }
    return [];
  };

  getOrigines = function getOrigines(product: Product): Picto[] {
    if (product.origins.length) {
      return filterOrigines(product.origins);
    }
    return [];
  };

  mounted(): void {
    this.initFormula();
  }

  initFormula(): void {
    this.products = this.formulaProducts(this.formula.code);
    this.productTypes = PRODUCT_TYPES.filter((type) =>
      this.products.some((product) => product.type === type.id)
    );
  }
}
