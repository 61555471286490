































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import BaseProduct from '../../menu/components/BaseProduct.vue';
import {Product} from '../../menu/interfaces';
import {FeedBackProduct, FeedBackProductCompleted} from '../interfaces';

@Component
export default class ModalComment extends BaseProduct {
  @Prop() readonly product!: Product;

  @Action('setFeedBackProduct', {namespace: 'feedback'}) setFeedBackProduct!: (
    feedBackProduct: FeedBackProduct | null
  ) => void;

  @Action('setFeedBackProductsCompleted', {namespace: 'feedback'})
  setFeedBackProductsCompleted!: (feedBackProduct: FeedBackProductCompleted) => void;

  @Action('sendFeedbackProduct', {namespace: 'feedback'})
  sendFeedbackProduct!: (feedBackProduct: FeedBackProduct) => void;

  @Action('reset', {namespace: 'feedback'}) resetError!: () => void;

  @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('feedBackProductsCompleted', {namespace: 'feedback'})
  feedBackProductsCompleted!: FeedBackProductCompleted[];

  @Getter('response', {namespace: 'feedback'}) response!: boolean;

  @Getter('error', {namespace: 'feedback'}) error!: boolean;

  dialog = false;

  redirectToForm = false;

  rating = 1;

  valid = false;

  @Watch('$store.state.feedback.response')
  getResponse(): void {
    if (this.response) {
      if (this.redirectToForm) {
        this.resetError();
        this.setFeedBackProduct(this.initFeedBackProduct());
        this.$router.push({name: 'contact'});
      }
    }
  }

  isCompletedFeedback(): boolean {
    const feedBackProductCompleted = this.feedBackProductsCompleted.find(
      (feedback) => feedback.id === this.product._id
    );
    if (feedBackProductCompleted) this.rating = feedBackProductCompleted.rating;
    return !!feedBackProductCompleted;
  }

  submitForm(): void {
    this.setFeedBackProductsCompleted({id: this.product._id, rating: this.rating});
    this.sendFeedbackProduct(this.initFeedBackProduct());
  }

  initFeedBackProduct(): FeedBackProduct {
    return {
      customerId: this.customerId,
      note: this.rating,
      name: this.product.name,
      price: this.product.price || undefined,
      sides: this.product.sides || undefined,
      dbSourceId: this.product.dbSourceId || undefined,
    };
  }
}
