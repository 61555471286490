import {Note} from '../interfaces/Note';

export const NOTES: Note[] = [
  {
    id: 0,
    color: '#CEC0C2',
    fileName: '0.svg',
  },
  {
    id: 1,
    color: '#F7435D',
    fileName: '1.svg',
  },
  {
    id: 2,
    color: '#F9594F',
    fileName: '2.svg',
  },
  {
    id: 3,
    color: '#FC793B',
    fileName: '3.svg',
  },
  {
    id: 4,
    color: '#FF902C',
    fileName: '4.svg',
  },
  {
    id: 5,
    color: '#FFB926',
    fileName: '5.svg',
  },
  {
    id: 6,
    color: '#EFE020',
    fileName: '6.svg',
  },
  {
    id: 7,
    color: '#C1DB2D',
    fileName: '7.svg',
  },
  {
    id: 8,
    color: '#83D63A',
    fileName: '8.svg',
  },
  {
    id: 9,
    color: '#48D169',
    fileName: '9.svg',
  },
  {
    id: 10,
    color: '#0ECC96',
    fileName: '10.svg',
  },
];
