import {UserState} from '../interfaces/UserState';

export const getters = {
  accessToken: (state: UserState): string => state.accessToken,
  balance: (state: UserState): number => state.balance,
  userId: (state: UserState): string => state.userId,
  email: (state: UserState): string => state.email,
  error: (state: UserState): boolean => state.error,
  loading: (state: UserState): boolean => state.loading,
  response: (state: UserState): boolean => state.response,
  errorMessage: (state: UserState): string => state.errorMessage,
  isNewUser: (state: UserState): boolean => state.isNewUser,
};
