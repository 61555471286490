















































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {validationMixin} from 'vuelidate';
import {email, minValue, required} from 'vuelidate/lib/validators';
import {Action, Getter} from 'vuex-class';

import Iframe from '@/core/components/Iframe.vue';
import {formatDate, mdiSvg, nameRoutes, PhoneTools} from '@/core/tools';
import {Links} from '@/site/interfaces';

import {Booking, BookingConfiguration, CountBooking, Slot} from '../interfaces';
import {
  formatBookingDate,
  getAvailableSeats,
  isAvailableSeats,
  isAvailableSlot,
  isClosedDate,
  isClosedDay,
  onBookableDays,
} from '../tools';

@Component({
  components: {Iframe},
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
      mobile: {
        isValid(mobile: string) {
          if (mobile) {
            const mobileNumber = new PhoneTools(mobile, 'FR');
            return mobileNumber.isValid();
          }
          return false;
        },
      },
      comment: {},
      seats: {required, minValue: minValue(1)},
    },
  },
})
export default class BookingForm extends Vue {
  @Action('countBookings', {namespace: 'booking'}) getCountBookings!: (params: {
    customerId: string;
    date: string;
  }) => void;

  @Action('reset', {namespace: 'booking'}) reset!: () => void;

  @Action('changeStep', {namespace: 'booking'}) changeStep!: (step: number) => void;

  @Action('sendBooking', {namespace: 'booking'}) sendBooking!: (booking: Booking) => void;

  @Getter('enableBooking', {namespace: 'site'}) enableBooking!: boolean;

  @Getter('bookingConfig', {namespace: 'booking'}) bookingConfig!: BookingConfiguration;

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('countBookings', {namespace: 'booking'}) countBookings!: CountBooking[];

  @Getter('error', {namespace: 'booking'}) error!: boolean;

  @Getter('errorMessage', {namespace: 'booking'}) errorMessage!: string;

  @Getter('loading', {namespace: 'booking'}) loading!: boolean;

  @Getter('response', {namespace: 'booking'}) response!: boolean;

  @Getter('links', {namespace: 'site'}) links!: Links;

  svgIcons = mdiSvg;

  dialog = false;

  stepReservation = 1;

  stepPanel = 0;

  today = new Date(Date.now()).toISOString();

  form = {
    customerId: '',
    date: '',
    slot: '',
    seats: 0,
    lastName: '',
    firstName: '',
    mobile: '',
    email: '',
    comment: '',
  };

  isIncrementActive = false;

  isDecrementActive = false;

  valid = false;

  selectedSlot: Slot | null = null;

  routes = nameRoutes;

  countSeats = 0;

  set selectedDate(value: string) {
    if (this.form.date !== value) {
      this.form.seats = 0;
    }
    this.form.date = value;
    this.getCountBookings({customerId: this.customerId, date: this.form.date});
    this.stepPanel = 1;
  }

  get selectedDate(): string {
    return this.form.date;
  }

  getFormatDate = function getFormatDate(date: string): string {
    return formatDate(date);
  };

  allowedDates(date: string): boolean {
    return (
      !isClosedDay(this.bookingConfig.workingDays)(date) &&
      !isClosedDate(this.bookingConfig.closedDates)(date) &&
      onBookableDays(date, this.bookingConfig.bookableDays) &&
      this.bookingConfig.slots.some((s) => isAvailableSlot(s.startTime, date))
    );
  }

  onSelectSlot(slot: Slot): void {
    this.selectedSlot = slot;
    this.form.slot = `${slot.startTime}`;
    //       this.form.slot = `${slot.startTime}-${slot.endTime}`;
    this.form.seats = 0;
    this.stepPanel = 2;
    this.countSeats = this.onAvailableSeats(this.selectedSlot);
  }

  onAvailableSlot(slot: Slot): boolean {
    return !(
      isAvailableSlot(slot.startTime, this.form.date) &&
      isAvailableSeats(slot.startTime, slot.seats, this.countBookings)
    );
  }

  onAvailableSeats(slot: Slot): number {
    return getAvailableSeats(slot.startTime, slot.seats, this.countBookings);
  }

  decrement(): void {
    this.form.seats -= 1;
    if (this.form.seats > 0) {
      this.isDecrementActive = true;
      this.isIncrementActive = false;
      setTimeout(() => {
        this.isDecrementActive = false;
      }, 1000);
    } else {
      this.isDecrementActive = false;
    }
  }

  increment(): void {
    this.form.seats += 1;
    if (this.selectedSlot && this.form.seats <= this.countSeats) {
      this.isIncrementActive = true;
      this.isDecrementActive = false;
      setTimeout(() => {
        this.isIncrementActive = false;
      }, 1000);
    } else {
      this.isIncrementActive = false;
    }
  }

  updateStep(step: number): void {
    this.stepReservation = step;
    this.changeStep(this.stepReservation);
  }

  seatsRules(v: number) {
    if (v > 0 && v <= this.countSeats) return true;
    return `Dépassement du nombre de places disponibles pour ce créneau`;
  }

  nameErrors(prop: string): string[] {
    const errors: string[] = [];
    if (!this.$v.form[prop]?.$dirty) return errors;
    if (!this.$v.form[prop]?.required) errors.push('Ce champ est obligatoire');
    return errors;
  }

  emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.form.email?.$dirty) return errors;
    if (!this.$v.form.email?.email) errors.push("L'email doit être valide");
    if (!this.$v.form.email?.required) errors.push('Ce champ est obligatoire');
    return errors;
  }

  mobileErrors(): string[] {
    const errors: string[] = [];
    if (this.$v.form.mobile?.$model && !this.$v.form.mobile?.isValid) {
      errors.push('Le numéro de téléphone doit être valide');
    }
    return errors;
  }

  submitForm(): void {
    if (this.selectedSlot) {
      const date = formatBookingDate(this.selectedSlot.startTime, this.form.date);
      const booking: Booking = {
        customerId: this.customerId,
        date,
        seats: this.form.seats,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        mobile: this.form.mobile || undefined,
        comment: this.form.comment || undefined,
      };
      this.sendBooking(booking);
    }
  }

  resetForm(): void {
    this.dialog = false;
    this.stepReservation = 1;
    this.stepPanel = 0;
    this.isIncrementActive = false;
    this.isDecrementActive = false;
    this.selectedSlot = null;
    this.form = {
      customerId: '',
      date: '',
      slot: '',
      seats: 0,
      lastName: '',
      firstName: '',
      mobile: '',
      email: '',
      comment: '',
    };
    this.$v.$reset();
    this.reset();
    this.changeStep(1);
  }
}
