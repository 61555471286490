/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
import PhoneNumber, * as AwesomePhoneNumber from 'awesome-phonenumber';

type PhoneNumberFormat = 'e164' | 'international' | 'national' | 'rfc3966' | 'significant';

// @see https://github.com/grantila/awesome-phonenumber
// Demo: https://rawgit.com/googlei18n/libphonenumber/master/javascript/i18n/phonenumbers/demo-compiled.html
export class PhoneTools {
  private readonly pn: AwesomePhoneNumber.default;

  constructor(phoneNumber: string, countryCode = 'FR') {
    this.pn = new PhoneNumber(phoneNumber, countryCode);
    return this;
  }

  get phoneNumber(): PhoneNumber {
    return this.pn;
  }

  get AwesomePhoneNumber() {
    return AwesomePhoneNumber.default;
  }

  static get AwesomePhoneNumber() {
    return AwesomePhoneNumber.default;
  }

  /**
   * Replace "00" by "+"" (Ex.: 0033... to +33...)
   */
  static cleanInternationalNumber(number?: string): string | undefined {
    return number?.trim().replace(/^00/, '+');
  }

  isValid(): boolean {
    return this.pn.isPossible() && this.pn.isValid();
  }

  isMobile(): boolean {
    return this.pn.isMobile();
  }

  /**
   *
   * @param type PhoneNumberFormat
   * ("e164" | "international" | "national" | "rfc3966" | "significant")
   * @returns a formated phone number
   */
  getNumber(type?: PhoneNumberFormat): string {
    return this.pn.getNumber(type);
  }
}
