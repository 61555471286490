import {AxiosResponse} from 'axios';

import axios from '@/core/tools/axios';

export default class MenuService {
  static getListMenus(id: string): Promise<AxiosResponse> {
    return axios.get(`/menus/site/${id}`);
  }

  static getMenu(id: string): Promise<AxiosResponse> {
    return axios.get(`/menus/${id}`);
  }
}
