
























































































































/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {VueTube} from 'vuetube';

import {formatDate, mdiSvg} from '@/core';

import {Article} from '../interfaces';

@Component({components: {VueTube}})
export default class ModalPostDetail extends Vue {
  @Prop() readonly article!: Article;

  @Watch('$route.hash')
  updateDialog(newHash: string, oldHash: string): void {
    if (newHash === `#${this.article.title}`) {
      this.dialog = true;
    } else if (oldHash === `#${this.article.title}`) {
      this.dialog = false;
    }
  }

  dialog = false;

  ytbRegex = /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/;

  playerInstance: any;

  svgIcons = mdiSvg;

  set isClosed(value: boolean) {
    if (!value) {
      this.closeModal();
    }
    this.dialog = value;
  }

  get isClosed(): boolean {
    return this.dialog;
  }

  updateRoute(open: boolean): void {
    if (open) {
      this.$router.push(`#${this.article.title}`);
    } else {
      this.$router.back();
    }
  }

  getYouTubeId(): string {
    if (this.article.youtubeUrl) {
      const arr = this.article.youtubeUrl.split(this.ytbRegex);
      return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
    }
    return '';
  }

  getThumbnail(): string {
    return `https://img.youtube.com/vi/${this.getYouTubeId()}/sddefault.jpg`;
  }

  getCreatedDate(): string {
    return formatDate(this.article.updatedAt);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onPlayerReady(event: any): void {
    this.playerInstance = event.target;
  }

  closeModal(): void {
    if (this.playerInstance) {
      this.playerInstance.stopVideo();
    }
  }

  strippedHtml(): string {
    return this.article.body.replace(/<[^>]+>/g, '');
  }
}
