/* eslint-disable @typescript-eslint/ban-types */
import ArticleService from '../services/ArticleService';

export const actions = {
  getArticles: async ({commit}: {commit: Function}, id: string): Promise<void> => {
    ArticleService.getArticles(id).then((response) => {
      commit('article/SET_ARTICLES', response.data, {root: true});
    });
  },
};
