






































































































import {Component, Prop} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import ModalComment from '@/feedback/components/ModalComment.vue';

import {PRODUCT_TYPE} from '../constants';
import {Product} from '../interfaces';
import BaseProduct from './BaseProduct.vue';

@Component({
  components: {ModalComment},
})
export default class ModalProduct extends BaseProduct {
  @Prop() readonly product!: Product;

  @Prop() readonly isActivatedComment!: boolean;

  @Getter('enableFeedback', {namespace: 'site'}) enableFeedback!: boolean;

  typeProduct = PRODUCT_TYPE;
}
