import {Nutriscore, Picto} from '../interfaces';
import {PRODUCT_TYPE} from './enums';

export const ALLERGENES: Picto[] = [
  {
    name: 'Arachides',
    id: 'ARA',
    fileName: 'arachides.svg',
  },
  {
    name: 'Celeri',
    id: 'CEL',
    fileName: 'celeri.svg',
  },
  {
    name: 'Crustacés',
    id: 'CRU',
    fileName: 'crustaces.svg',
  },
  {
    name: 'Fruits à coque',
    id: 'FCQ',
    fileName: 'fruits-a-coque.svg',
  },
  {
    name: 'Gluten',
    id: 'GLU',
    fileName: 'gluten.svg',
  },
  {
    name: 'Lait',
    id: 'LAI',
    fileName: 'lait.svg',
  },
  {
    name: 'Lupin',
    id: 'LUP',
    fileName: 'lupin.svg',
  },
  {
    name: 'Mollusques',
    id: 'MOL',
    fileName: 'mollusques.svg',
  },
  {
    name: 'Moutarde',
    id: 'MOU',
    fileName: 'moutarde.svg',
  },
  {
    name: 'Oeuf',
    id: 'OEU',
    fileName: 'oeuf.svg',
  },
  {
    name: 'Poisson',
    id: 'POI',
    fileName: 'poisson.svg',
  },
  {
    name: 'Sésame',
    id: 'SES',
    fileName: 'sesame.svg',
  },
  {
    name: 'Soja',
    id: 'SOJ',
    fileName: 'soja.svg',
  },
  {
    name: 'Sulfite',
    id: 'SUL',
    fileName: 'sulfite.svg',
  },
];

export const ORIGINES: Picto[] = [
  {
    name: 'Bio',
    id: 'BIO',
    fileName: 'ab.svg',
  },
  {
    name: 'Aubrac',
    id: 'ABC',
    fileName: 'abc.svg',
  },
  {
    name: 'AOP',
    id: 'AOP',
    fileName: 'aop.svg',
  },
  {
    name: 'Bleu blanc coeur',
    id: 'BBC',
    fileName: 'bbc.svg',
  },
  {
    name: 'Equilibré',
    id: 'ELE',
    fileName: 'ele.svg',
  },
  {
    name: 'Français',
    id: 'FCS',
    fileName: 'fcs.svg',
  },
  {
    name: 'Fait maison',
    id: 'FMN',
    fileName: 'fmn.svg',
  },
  {
    name: 'IGP',
    id: 'IGP',
    fileName: 'igp.svg',
  },
  {
    name: "Porc fermier d'Auvergne",
    id: 'PFA',
    fileName: 'pfa.svg',
  },
  {
    name: 'Porc français',
    id: 'POF',
    fileName: 'pof.svg',
  },
  {
    name: 'Végétarien',
    id: 'VTN',
    fileName: 'vtn.svg',
  },
];

export const PRODUCT_TYPES: Picto[] = [
  {
    name: 'Entrée',
    id: PRODUCT_TYPE.ENT,
    fileName: 'entrees.svg',
  },
  {
    name: 'Plat',
    id: PRODUCT_TYPE.PLA,
    fileName: 'plats.svg',
  },
  {
    name: 'Accompagnement',
    id: PRODUCT_TYPE.ACC,
    fileName: 'accompagnements.png',
  },
  {
    name: 'Fromage',
    id: PRODUCT_TYPE.FRO,
    fileName: 'fromages.svg',
  },
  {
    name: 'Dessert',
    id: PRODUCT_TYPE.DES,
    fileName: 'desserts.svg',
  },
  // {
  //   name: 'Laitage',
  //   id: PRODUCT_TYPE.LAI,
  //   fileName: 'laitages.svg',
  // },
  {
    name: 'Boisson',
    id: PRODUCT_TYPE.BOI,
    fileName: 'boissons.svg',
  },
  // {
  //   name: 'Autre',
  //   id: PRODUCT_TYPE.AUT,
  //   fileName: 'formules.svg',
  // },
  // {
  //   name: 'Formule',
  //   id: PRODUCT_TYPE.FOR,
  //   fileName: 'formules.svg',
  // },
];

export const NUTRISCORE: Nutriscore[] = [
  {
    id: 1,
    fileName: '1.svg',
    fileNameVertical: 'v_1.svg',
  },
  {
    id: 2,
    fileName: '2.svg',
    fileNameVertical: 'v_2.svg',
  },
  {
    id: 3,
    fileName: '3.svg',
    fileNameVertical: 'v_3.svg',
  },
  {
    id: 4,
    fileName: '4.svg',
    fileNameVertical: 'v_4.svg',
  },
  {
    id: 5,
    fileName: '5.svg',
    fileNameVertical: 'v_5.svg',
  },
];

export const ZONES: Picto[] = [
  {
    name: "L'animé",
    id: 'AIE',
    fileName: 'aie.svg',
  },
  {
    name: 'Le boucher',
    id: 'BCR',
    fileName: 'bcr.svg',
  },
  {
    name: 'Le minute',
    id: 'LIE',
    fileName: 'lie.svg',
  },
  {
    name: 'Le Mareyeur',
    id: 'MYR',
    fileName: 'myr.svg',
  },
];
