import {RouteConfig} from 'vue-router';

import {nameRoutes} from '@/core';

import Menu from './views/Menu.vue';

export const menuRoutes: Array<RouteConfig> = [
  {
    path: '/:slug/menus',
    name: nameRoutes.menu,
    component: Menu,
    meta: {title: 'Menu'},
  },
];
