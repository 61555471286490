import {AxiosResponse} from 'axios';

import axios from '@/core/tools/axios';

import {Booking} from '../interfaces';

export default class BookingService {
  static createBooking(booking: Booking): Promise<AxiosResponse> {
    return axios.post('/booking/', booking);
  }

  static cancelBooking(id: string): Promise<AxiosResponse> {
    return axios.delete(`/booking/${id}`);
  }

  static getBooking(id: string): Promise<AxiosResponse> {
    return axios.get(`/booking/${id}`);
  }

  static countBookings(customerId: string, date: string): Promise<AxiosResponse> {
    return axios.get(`/booking/count/${customerId}/${date}/`);
  }
}
