import {FeedBackProduct, FeedBackProductCompleted, FeedbackState} from '../interfaces';

export const mutations = {
  SET_ERROR(state: FeedbackState, error: boolean): void {
    state.error = error;
  },
  SET_ERROR_MESSAGE(state: FeedbackState, errorMessage: string): void {
    state.errorMessage = errorMessage;
  },
  SET_LOADING(state: FeedbackState, loading: boolean): void {
    state.loading = loading;
  },
  SET_RESPONSE(state: FeedbackState, response: boolean): void {
    state.response = response;
  },
  SET_FEEDBACK_PRODUCT(state: FeedbackState, feedBackProduct: FeedBackProduct | null): void {
    state.feedBackProduct = feedBackProduct;
  },
  SET_FEEDBACK_PRODUCTS_COMPLETED(
    state: FeedbackState,
    feedBackProductCompleted: FeedBackProductCompleted
  ): void {
    state.feedBackProductsCompleted.push(feedBackProductCompleted);
  },
};
