export function formatPrice(value: string): string {
  if (value) {
    if (value === '-1') {
      return 'Error';
    }
    const number = parseFloat(value.replace(',', '.'));
    if (Number.isNaN(number)) {
      return value;
    }
    const string = number.toFixed(2);
    const parts = string.split('.');
    if (parts.length === 2) {
      const int = parts[0];
      const decimals = parts[1];
      const mode = `${int},${decimals}€`;
      return mode;
    }
  }
  return value;
}
