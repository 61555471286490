import {actions} from './actions';
import {getters} from './getters';
import {mutations} from './mutations';
import {state} from './state';

// Export reservation store
export const booking = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
