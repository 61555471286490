import {actions} from './actions';
import {getters} from './getters';
import {mutations} from './mutations';
import {state} from './state';

// Export menu store
export const menu = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
