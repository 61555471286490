





























import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {PRODUCT_TYPE} from '../constants';
import {Picto, Product, Zone} from '../interfaces';
import {filterTypesProduct} from '../tools';

@Component
export default class SelectProductType extends Vue {
  @Action('updateCurrentProductTypes', {namespace: 'menu'}) updateCurrentProductTypes!: (
    productTypes: Picto[]
  ) => void;

  @Action('updateSelectedProductTypeName', {namespace: 'menu'}) updateSelectedProductTypeName!: (
    id: string
  ) => void;

  @Getter('loadingMenu', {namespace: 'menu'}) loadingMenu!: boolean;

  @Getter('products', {namespace: 'menu'}) products!: Product[];

  @Getter('formulaZones', {namespace: 'menu'}) formulaZones!: Zone[];

  @Getter('formulaProducts', {namespace: 'menu'}) formulaProducts!: (id: string) => Product[];

  @Getter('error', {namespace: 'menu'}) error!: boolean;

  @Getter('selectedProductTypeName', {namespace: 'menu'}) selectedProductTypeName!: string;

  productTypes: Picto[] = [];

  typeProduct = PRODUCT_TYPE;

  mounted(): void {
    this.updateProductTypes();
  }

  @Watch('$store.state.menu.currentMenu')
  updateCurrentMenu(): void {
    this.updateProductTypes();
  }

  set selectedProductType(value: string) {
    this.updateSelectedProductTypeName(value);
  }

  get selectedProductType(): string {
    return this.selectedProductTypeName;
  }

  productsTypes(typeProduct: PRODUCT_TYPE | string): Product[] {
    return this.products.filter((product) => product.type === typeProduct);
  }

  hasProductsTypes(): boolean {
    return this.productTypes.some((productType) => this.productsTypes(productType.id).length);
  }

  hasProductsFormulas(): boolean {
    return this.formulaZones.some((formula) => this.formulaProducts(formula.code).length);
  }

  updateProductTypes(): void {
    this.productTypes = filterTypesProduct(this.products);
    // if (this.formulaZones.length && this.hasProductsFormulas()) {
    //   this.productTypes.push(PRODUCT_TYPES[8]);
    // }
    this.updateCurrentProductTypes(this.productTypes);
  }

  scrollToProductType(productType: string): void {
    const element = document.getElementById(productType);
    if (element) {
      window.scrollTo({left: 0, top: element.offsetTop, behavior: 'smooth'});
    }
  }
}
