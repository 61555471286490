/* eslint-disable @typescript-eslint/ban-types */
import {FeedbackContact, FeedBackProduct, FeedBackProductCompleted} from '../interfaces';
import FeedbackService from '../services/FeedbackService';

export const actions = {
  sendFeedback: async (
    {commit}: {commit: Function},
    feedbackContact: FeedbackContact
  ): Promise<void> => {
    commit('SET_ERROR', false);
    commit('SET_LOADING', false);
    FeedbackService.sendFeedback(feedbackContact)
      .then((response) => {
        if (response.data.code === 200) commit('SET_RESPONSE', true);
        else {
          commit('SET_ERROR', true);
          commit('SET_ERROR_MESSAGE', response.data.message);
        }
      })
      .catch(() => {
        commit('SET_ERROR', true);
      })
      .finally(() => commit('SET_LOADING', false));
  },
  sendFeedbackProduct: async (
    {commit}: {commit: Function},
    feedbackProduct: FeedBackProduct
  ): Promise<void> => {
    commit('SET_ERROR', false);
    commit('SET_LOADING', false);
    FeedbackService.sendFeedbackProduct(feedbackProduct)
      .then((response) => {
        if (response.data.code === 201) commit('SET_RESPONSE', true);
        else {
          commit('SET_ERROR', true);
          commit('SET_ERROR_MESSAGE', response.data.message);
        }
      })
      .catch(() => {
        commit('SET_ERROR', true);
      })
      .finally(() => commit('SET_LOADING', false));
  },
  setFeedBackProduct: (
    {commit}: {commit: Function},
    feedBackProduct: FeedBackProduct | null
  ): void => {
    commit('SET_FEEDBACK_PRODUCT', feedBackProduct);
  },
  setFeedBackProductsCompleted: (
    {commit}: {commit: Function},
    feedBackProductCompleted: FeedBackProductCompleted
  ): void => {
    commit('SET_FEEDBACK_PRODUCTS_COMPLETED', feedBackProductCompleted);
  },
  reset: ({commit}: {commit: Function}): void => {
    commit('SET_ERROR', false);
    commit('SET_RESPONSE', false);
    commit('SET_LOADING', false);
    commit('SET_ERROR_MESSAGE', '');
  },
};
