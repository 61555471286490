import {AffluenceResponse, AffluenceZone, Links, Site, SiteState} from '../interfaces';

export const getters = {
  affluenceResponse: (state: SiteState): AffluenceResponse[] => state.affluenceResponse,
  affluenceZones: (state: SiteState): AffluenceZone[] => state.affluenceZones,
  brandId: (state: SiteState): string => state.brandId,
  customerId: (state: SiteState): string => state.customerId,
  enableAffluence: (state: SiteState): boolean => state.enableAffluence,
  enableContact: (state: SiteState): boolean => state.enableContact,
  enableFeedback: (state: SiteState): boolean => state.enableFeedback,
  error: (state: SiteState): boolean => state.error,
  isBookingActivated: (state: SiteState): boolean => state.isBookingActivated,
  isDemo: (state: SiteState): boolean => state.isDemo,
  isOpenSelector: (state: SiteState): boolean => state.isOpenSelector,
  links: (state: SiteState): Links | null => state.links,
  loading: (state: SiteState): boolean => state.loading,
  motd: (state: SiteState): string | null => state.motd,
  name: (state: SiteState): string => state.name,
  slug: (state: SiteState): string => state.slug,
  siteList: (state: SiteState): Site[] => state.siteList,
  enableBooking: (state: SiteState): boolean => state.enableBooking,
};
