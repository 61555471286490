import {SiteState} from '../interfaces';

export const state: SiteState = {
  error: false,
  links: null,
  loading: false,
  name: '',
  customerId: '',
  isBookingActivated: false,
  slug: '',
  isDemo: false,
  motd: null,
  enableContact: false,
  enableFeedback: false,
  isOpenSelector: false,
  siteList: [],
  enableAffluence: false,
  affluenceZones: [],
  affluenceResponse: [],
  brandId: '',
  enableBooking: false,
};
