import {NavigationGuardNext, Route, RouteConfig} from 'vue-router';

import {store} from '@/core/store';

import BookingForm from './components/BookingForm.vue';
import CancelBooking from './components/CancelBooking.vue';
import BookingService from './services/BookingService';

export const bookingRoutes: Array<RouteConfig> = [
  {
    path: '/reservation/:id',
    name: 'redirectCancelBooking',
    meta: {title: 'Redirection annulation réservation'},
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      try {
        const response = await BookingService.getBooking(to.params.id);
        if (response && response.status === 200) {
          store.commit('booking/SET_CURRENT_BOOKING', response.data, {root: true});
          await store
            .dispatch('site/getSiteByCustomerId', response.data.customerId)
            .then((slug) => {
              next(`/${slug}/annuler-reservation/${to.params.id}`);
            });
        }
      } catch (error) {
        next('/non-trouve');
      }
    },
  },
  {
    path: '/:slug/reservation',
    name: 'booking',
    component: BookingForm,
    meta: {title: 'Réservation'},
  },
  {
    path: '/:slug/annuler-reservation/:id',
    name: 'cancelBooking',
    component: CancelBooking,
    meta: {title: 'Annulation réservation'},
  },
];
